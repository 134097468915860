<template>
  <div>
    <h3> Distance between Two Points </h3>
    <p class="big my-3">
      In this section, we will find the formula for the distance between any two points on the coordinate plane.
      <br>      Consider two points \(A\) and \(B\) on the coordinate plane.
      Point \(A\) has coordinates: \((X_A, Y_A)\), while point \(B\) has
      coordinates: \((X_B, Y_B)\).
      <br>
      Then, the distance between these two points is given as &mdash;
    </p><body>
      $$AB = \sqrt{(X_B - X_A)^2 + (Y_B-Y_A)^2}$$
      This expression is known as distance formula.
    </body>
    </p>
    <h3> MagicGraph | Distance Formula Explained </h3>
    <br>
    <h5>Getting Started</h5>
    <p class="big my-3">
      In this MagicGraph, you will learn, in a step by step manner, how to find the distance between any two points on coordinate plane.
      <br>
      Simply use <i class="redoer ma-1" /> and <i class="undoer ma-1" /> icons to go through the steps
      required to find the distance between points A and B.
    </p>
    <h5> Icons on the MagicGraph </h5>
    <p class="big my-3">
      You can tap on <i class="shuffler ma-1" /> icon  to shuffle events.
      You can tap on the <i class="eraser ma-1" /> icon to erase and start over.
      Tap on the <i class="redoer ma-1" /> icon to go to the next step.
      To go to the previous step, tap on the <i class="undoer ma-1" /> icon.
    </p>
    <br>
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'DistanceFormula',
  //async
  created () {
    //await this.$store.dispatch('navigation/getData');
    this.$store.commit('navigation/resetState');
    let title = 'Distance Formula';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Square Formula', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('playgraph')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Distance Formula',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about square formula'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
</style>
