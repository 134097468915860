// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeCircle,
    placeLogo,
    placeShuffle,
    placeTest,
    placeUndo,
    placeRedo,
    placeBoldText,
    placeBoldLeftText,
    placeErase,
    placeLabel,
    placeDash,
    placeTapeHori,
    placeTapeVert,
    placeText,
    placeHTMLText,
    placeAngle
} from '../../../../common/edliy_utils-geometric';
const Boxes = {
  box1: function () {
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-12, 12, 8, -8],
    keepaspectratio: true, axis:true, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
  //brd1.suspendUpdate();
  makeResponsive(brd1);
  brd1.options.layer['text'] =30;
  brd1.options.layer['image'] =30;
  brd1.options.layer['point'] =20;
  brd1.options.layer['line'] =15;
//	brd1.create('text', [6, 11.5, 'Fraction'],{highlight:false, display:'internal', anchorX:'middle', anchorY:'middle', CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(32*brd1.canvasWidth/800.)}, fixed:true});
  placeTitle(brd1, 'Distance Formula', 'Find distance between points A and B');
  placeLogo(brd1);
  var i=0; var j=0;
  ////////////////////////////////////////////////////////////////////////////////
      var shuffle = placeShuffle(brd1);
      shuffle.setAttribute({visible:()=>i==0})
      shuffle.setLabel('Tap to Shuffle Events');
      shuffle.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
      shuffle.on('over', function () {this.label.setAttribute({visible:true});});
      shuffle.on('out', function () {this.label.setAttribute({visible:false});});
///////////////////////////////////////////////////////////////////////////////////
      var test = placeTest(brd1);
      test.setAttribute({visible:()=>i==3})
      test.setLabel('Tap to Start Over');
      test.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
      test.on('over', function () {this.label.setAttribute({visible:true});});
      test.on('out', function () {this.label.setAttribute({visible:false});});
////////////////////////////////////////////
var pts1 = brd1.create('point', [2., 2.], {name:'', highlight:false, size:function(){return 4*brd1.canvasHeight/800}, fillColor:'orange', strokeColor:'black', strokeWidth:1, visible:true, snapToGrid:true, shadow:true});
placeLabel(brd1, pts1, 'A', 0, 15);
brd1.create('segment', [[()=>pts1.X(), ()=>pts1.Y()], [0, ()=>pts1.Y()]],{dash:1, strokeWidth:2, strokeColor:'black'});
brd1.create('segment', [[()=>pts1.X(), ()=>pts1.Y()], [()=>pts1.X(), 0]],{dash:1, strokeWidth:2, strokeColor:'black'});
///////////////////////////////////////////////////////////////////////
var pts2 = brd1.create('point', [-3., -4.], {name:'', highlight:false, size:function(){return 4*brd1.canvasHeight/800}, fillColor:'orange', strokeColor:'black', strokeWidth:1, visible:true, snapToGrid:true, shadow:true});
placeLabel(brd1, pts2, 'B', -15, -15);
brd1.create('segment', [[()=>pts2.X(), ()=>pts2.Y()], [0, ()=>pts2.Y()]],{dash:1, strokeWidth:2, strokeColor:'black'});
brd1.create('segment', [[()=>pts2.X(), ()=>pts2.Y()], [()=>pts2.X(), 0]],{dash:1, strokeWidth:2, strokeColor:'black'});
//////////////////////////////////////////////////////
var pts3 = brd1.create('point', [()=>pts1.X(), ()=>pts2.Y()], {name:'', highlight:false, size:function(){return 4*brd1.canvasHeight/800}, fillColor:'orange', strokeColor:'black', strokeWidth:1, visible:true, snapToGrid:true, shadow:true});
placeLabel(brd1, pts3, 'C', 15, -15);
pts3.setAttribute({visible:()=>i>=1})
//////////////////////////////////////////////////////////
placeLine(brd1, [()=>pts2.X(), ()=>pts2.Y()], [()=>pts1.X(), ()=>pts1.Y()], 3,'black');
var Horz =placeTapeHori(brd1, [()=>pts2.X(), ()=>pts2.Y()], [()=>pts1.X(), ()=>pts2.Y()], 'x2 - x1');
Horz.setAttribute({visible:()=>i>=2, highlight:true, fillOpacity:0.4,
  point1:{visible:false},point2:{visible:false}, strokeWidth:3, strokeColor:'red', label:{visible:()=>i>=5, color:'red'}});
/////
var horzDash =placeDash(brd1, [-12, ()=>pts2.Y()], [12, ()=>pts2.Y()], 2,'black');
horzDash.setAttribute({visible:()=>i>=1});
var vertDash = placeDash(brd1, [()=>pts1.X(), -12], [()=>pts1.X(), 12], 2,'black');
vertDash.setAttribute({visible:()=>i>=1});
////////////////////////////////////////
var Vert =placeTapeVert(brd1, [()=>pts1.X(), ()=>pts1.Y()], [()=>pts1.X(), ()=>pts2.Y()], 'Y2 - Y1');
Vert.setAttribute({visible:()=>i>=2, highlight:true, fillOpacity:0.4,
  point1:{visible:false},point2:{visible:false}, strokeWidth:3, strokeColor:'blue', label:{visible:()=>i>=5, color:'blue'}});
/////////////////////////////////////////////////////////////
var xAxis =placeHTMLText(brd1, ()=>(pts1.X()+pts2.X())/2, ()=>pts2.Y()-0.5, 18, ()=>'X_B-X_A = '+(Horz.Value()).toFixed(2));
xAxis.setAttribute({color:'red', visible:()=>i>=2});

var yAxis =placeHTMLText(brd1, ()=>pts1.X()+0.25, ()=>(pts1.Y()+pts2.Y())/2, 18, ()=>'Y_B-Y_A = '+(Vert.Value()).toFixed(2));
yAxis.setAttribute({anchorX:'left', color:'blue', visible:()=>i>=2});

var ang = placeAngle(brd1, [()=>pts2.X(), ()=>pts2.Y()], [()=>pts1.X(), ()=>pts2.Y()], [()=>pts1.X(), ()=>pts1.Y()],
 1, 'green');
ang.setAttribute({label:{visible:false},visible:()=>i>=2});
//ang.setLabel({})
///////////////////////////////TAP OPERATIONS ////////////////////////////////
///////////////////////////////////////
var dal1 =placeHTMLText(brd1, -9, 3.5, 22,'AB =');
dal1.setAttribute({anchorX:'left',CssStyle:'fontFamily:Oswald;fontWeight:bold'});
var dal2 =placeHTMLText(brd1, -7.9, 3.5, 40,'&radic;');
dal2.setAttribute({anchorX:'left',CssStyle:'fontFamily:Oswald;fontWeight:bold'});
var dal3 =placeHTMLText(brd1, -7.3, 3.5, 18,'(X_B -X_A)^2 + (Y_B-Y_A)^2');
dal3.setAttribute({anchorX:'left',CssStyle:'fontFamily:Oswald;fontWeight:bold'});

////////////////////////////////////////////////////////
var val = placeText(brd1, -8, 2.5, 18,()=>'AB ='+(Math.pow(Horz.Value()*Horz.Value()+Vert.Value()*Vert.Value(), 0.5)).toFixed(2));
val.setAttribute({visible:()=>i>=3, anchorX:'left',CssStyle: 'fontFamily:Oswald;fontWeight:regular'});
/////////////////////////////////////
var xs = placeHTMLText(brd1, -8.1, 7.5, 22,  'X_B - X_A = ');
xs.setAttribute({anchorX:'right', CssStyle: 'fontFamily:Oswald;fontWeight:bold'});
/////////////////////////
placeInput(brd1, -8, 7.5, '');
var minus2 = placeHTMLText(brd1,-5.5, 7.5, 18, '&mdash;');
minus2.setAttribute({CssStyle: 'fontFamily:Oswald;fontWeight:bold'});
placeInput(brd1, -5, 7.5, '');
var ques1 =placeImage(brd1, '/assets/query.svg', -2.8, 7.2, 0.6, 0);
var rght1 = placeImage(brd1, '/assets/check.svg', -2.8, 7.2, 0.6, 0);
rght1.setAttribute({visible:false});
var wrng1 = placeImage(brd1, '/assets/cross.svg', -2.8, 7.2, 0.6, 0);
wrng1.setAttribute({visible:false});
////////////////////////////////////////////
var ys = placeHTMLText(brd1, -8.1, 5.5, 22,  'Y_B - Y_A = ');
ys.setAttribute({anchorX:'right', CssStyle: 'fontFamily:Oswald;fontWeight:bold'});
/////////////////////////
placeInput(brd1, -8, 5.5, '');
var minus1 = placeHTMLText(brd1,-5.5, 5.5, 18, '&mdash;');
minus1.setAttribute({CssStyle: 'fontFamily:Oswald;fontWeight:bold'});
placeInput(brd1, -5, 5.5, '');
var ques2 =placeImage(brd1, '/assets/query.svg', -2.8, 5.2, 0.6, 0);
var rght2 = placeImage(brd1, '/assets/check.svg', -2.8, 5.2, 0.6, 0);
rght2.setAttribute({visible:false});
var wrng2 = placeImage(brd1, '/assets/cross.svg', -2.8, 5.2, 0.6, 0);
wrng2.setAttribute({visible:false});
////////////////////////////////////////////

analytics.on('down', function(){
  if(i>0)
  {
  i-=1;
  }
  else
  {
    i=0;
  }
});
/////////////////////////////////////OPERATION 2 /////////////////////////////////
erase.on('down', function(){
  if(i<3)
  {
  i+=1;
  }
  else
  {
    i=3;
  }
})
///////////////////////////////////////////OPERATION 3 //////////////////////////////////////
shuffle.on('down', function(){
  i=0;
  pts1.moveTo([2+Math.round(3*Math.random()),2+Math.round(3*Math.random())]);
  pts2.moveTo([-5+Math.round(3*Math.random()),-4+Math.round(3*Math.random())]);
  if(j<4)
  {
  j+=1;
  }
  else
  {
  j=0;
  }
})
shuffle2.on('down', function(){
  i=0;
})
////////////////////////////////////////////END OF OPERATIONS///////////////////////////////
    },


}
export default Boxes;
